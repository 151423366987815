//开播申请接口
import { axios } from '@/utils/request'

/*
 * @Description: 直播申请分页查询
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-01 13:15:50
 */
// 字典分页查询
export function findApplyLiveStreamingPageList(parameter){
    return axios({
        url: '/applyLiveStreaming/page',
        method: 'post',
        params:parameter.page,
        data: parameter.queryParam
    })
}

/*
 * @Description: 直播申请详情查询
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-01 13:15:50
 */
// 字典分页查询
export function getApplyLiveStreamingDetail(parameter){
    return axios({
        url: '/applyLiveStreaming/detail',
        method: 'get',
        params: parameter
    })
}

/*
 * @Description: 直播申请审核
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-01 13:15:50
 */
// 字典分页查询
export function examineApplyLiveStreaming(parameter){
    return axios({
        url: '/applyLiveStreaming/examine',
        method: 'post',
        data: parameter
    })
}